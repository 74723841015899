import React from "react";

import Stack from './Stack/Stack.js';

import './DownCards.scss';

class DownCards extends React.Component {

    getCards ( stacks, ordinal )
    {
        if ( stacks && stacks !== null )
        {
            var index = stacks.findIndex ( s => s.ordinal === ordinal );
            return stacks[index].cards;
        }
        else
        {
            return [];
        }
    }

    render () {


        return <div className="stacks">
                    <Stack cards={this.getCards ( this.props.stacks, "three" )}
                        newCards={this.getCards ( this.props.newStacks, "three" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("three")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "four" )}
                        newCards={this.getCards ( this.props.newStacks, "four" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("four")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "five" )}
                        newCards={this.getCards ( this.props.newStacks, "five" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("five")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "six" )}
                        newCards={this.getCards ( this.props.newStacks, "six" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("six")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "seven" )}
                        newCards={this.getCards ( this.props.newStacks, "seven" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("seven")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "eight" )}
                        newCards={this.getCards ( this.props.newStacks, "eight" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("eight")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "nine" )}
                        newCards={this.getCards ( this.props.newStacks, "nine" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("nine")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "ten" )}
                        newCards={this.getCards ( this.props.newStacks, "ten" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("ten")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "jack" )}
                        newCards={this.getCards ( this.props.newStacks, "jack" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("jack")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "queen" )}
                        newCards={this.getCards ( this.props.newStacks, "queen" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("queen")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "king" )}
                        newCards={this.getCards ( this.props.newStacks, "king" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("king")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "ace" )}
                        newCards={this.getCards ( this.props.newStacks, "ace" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("ace")}/>
                    <Stack cards={this.getCards ( this.props.stacks, "wild" )}
                        newCards={this.getCards ( this.props.newStacks, "wild" )}
                        selectingStack={this.props.selectingStack}
                        onSelectStack={() => this.props.onStackSelected("wild")}/>
                </div>;
    }
}

export default DownCards;
