import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { TrackJS } from 'trackjs';

import './index.scss';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import Game from './Containers/Game/Game.js';
import GameList from './Containers/GameList/GameList.js';

axios.defaults.baseURL = 'https://canasta-api.rynwylf.com';

TrackJS.install({
  token: "f3add66a05394849966d533c40bf3645",
  application: "canasta"
  // for more configuration options, see https://docs.trackjs.com
});

ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/game/:id/:playerNumber?/:playerId?" render={routeProps => <Game {...routeProps} />}/>
                    <Route path="/" render={routeProps => <GameList {...routeProps} />}/>
                </Switch>
            </BrowserRouter>
        </React.StrictMode>,
    document.getElementById('root') );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
