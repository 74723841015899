import React from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";

import Joining from './Joining/Joining.js';
import Playing from './Playing/Playing.js';

class Game extends React.Component {

    timeout = 250;


    constructor ( props )
    {
        super ( props );
        this.state = { game: null, events: [] };
    }

    componentDidMount ()
    {
        this.connect ( this.props.match.params.id );
        this.refreshGame ();
    }

    /**
     * @function connect
     * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
     */
    connect = ( gameId ) => {
        var ws = new WebSocket ( "wss://canasta-api-ws.rynwylf.com/?gameId=" + gameId );
        let that = this; // cache the this
        var connectInterval;

        this.setState ( { gameId: gameId } );

        // websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");

            this.setState({ ws: ws });

            that.timeout = 250; // reset timer to 250 on open of websocket connection
            clearTimeout(connectInterval); // clear Interval on on open of websocket connection

            this.refreshGame ();
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,
                    (that.timeout + that.timeout) / 1000
                )} second.`,
                e.reason
            );

            that.timeout = that.timeout + that.timeout; //increment retry interval
            connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
        };

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );

            ws.close();
        };

        ws.onmessage = (message) => {
            this.handleMessage ( JSON.parse ( message.data ) );
        };
    };

    handleMessage ( message )
    {
        console.log ( JSON.stringify ( message ) );
        var events = [];

        if ( this.state.events )
        {
            events = [ ...this.state.events ];
        }

        events.push ( message.data.message );

        this.setState ( { events: events } );

        this.refreshGame ();
    }

    refreshGame () {

        if ( this.props.match.params.id &&
                this.props.match.params.playerNumber &&
                this.props.match.params.playerId  )
        {
            axios.get("/game/" + this.props.match.params.id + "/player/" + this.props.match.params.playerNumber + "/" + this.props.match.params.playerId)
                .then(json => this.setState({game: json.data}))
                .catch(error => this.setState({error: error}));
        }
        else if ( this.props.match.params.id )
        {
            axios.get("/game/" + this.props.match.params.id )
                .then(json => this.setState({game: json.data}))
                .catch(error => this.setState({error: error}));
        }
    }

    leaveHandler ( playerNumber, playerId )
    {
        this.state.ws.close ();
        this.props.history.push ( "/" );
    }

    /**
     * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
     */
    check = () => {
        // const { ws } = this.state;
        // if ( !ws || ws.readyState === WebSocket.CLOSED ) this.connect ();
    };

    render () {

        var playerNumber = null;
        var playerId = null;

        if ( this.props && this.props.match && this.props.match.params.playerNumber )
        {
            playerNumber = Number ( this.props.match.params.playerNumber );
        }

        if ( this.props && this.props.match && this.props.match.params.playerId )
        {
            playerId = this.props.match.params.playerId;
        }

        var avatar = this.state && this.state.game && this.state.game["player" + playerNumber] ? this.state.game["player" + playerNumber].avatar : null;

        if ( this.state && this.state.game && this.state.game.state === "OPEN" )
        {
            return <Joining game={this.state.game} playerNumber={playerNumber} playerId={playerId} avatar={avatar}
                            sitHandler={(playerNumber, playerId) => this.sitHandler(playerNumber, playerId)}
                            leaveHandler={(playerNumber, playerId) => this.leaveHandler(playerNumber, playerId)} />;
        }
        else if ( this.state && this.state.game && this.state.game.state !== "OPEN" )
        {
            return <Playing
                            game={this.state.game}
                            events={this.state.events}
                            playerNumber={Number(playerNumber)}
                            playerId={playerId} />
        }
        else
        {
            return <p>preparing</p>;
        }
    }
}

export default withRouter ( Game );
