import React from "react";

import "./Dialog.scss";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.keyFunction = this.keyFunction.bind(this);
  }

  keyFunction(event) {
    if (event.keyCode === 27) {
      this.props.onCancel();
    } else if (event.keyCode === 13) {
      this.props.onOK();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyFunction, false);
  }

  render() {
    const { children, onOK, onCancel } = this.props;
    return (
      <div id="dialog-wrapper">
        <div id="blind" />
        <div id="dialog" style={{zIndex:1000}}>
          {children}
          <div className="buttons">
            <button className="button-red" onClick={onOK}>
              OK
            </button>
            <button className="button-red" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
