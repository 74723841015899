import React from 'react';

import './Header.scss';

class Header extends React.Component {

    constructor ( props )
    {
        super ( props );

        this.state = { events: [ "", "", "" ] };
    }

    componentDidUpdate ( prevProps, prevState, snapshot )
    {
        if ( this.props.events.length > prevProps.events.length )
        {
            var newEvents = [...this.state.events];

            if ( newEvents.length > 1 )
            {
                newEvents.shift ();
            }

            newEvents.push ( this.props.events[this.props.events.length - 1] );
            this.setState ( { events: newEvents } );
        }
    }

    render () {

        var events = [];

        if ( this.state && this.state.events && this.state.events.length )
        {
            events = this.state.events.map ( ( message, index ) => <p key={index + message}
                            className={"message " + ( index === this.state.events.length - 1 ? "newMessage show " : "") + ( index === 0 ? " oldMessage hide" : "" ) }>
                        {message}
                    </p> );
        }

        return <div className="headerContainer">
                    <div className="header">
                        <h1>{this.props.name}<span className="state">{this.props.state}</span></h1>
                        <h2>Round {this.props.round}&nbsp;|&nbsp;
                            <span className={"score" + ( this.props.playerNumber === 1 || this.props.playerNumber === 3 ? " playerscore" : "" ) }>{this.props.score13}</span>
                            &nbsp;:&nbsp;
                            <span className={"score" + ( this.props.playerNumber === 2 || this.props.playerNumber === 4 ? " playerscore" : "" ) }>{this.props.score24}</span>
                        </h2>
                    </div>
                    <div className="notices">
                        {events}
                    </div>
                </div>;
    }
}

export default Header;
