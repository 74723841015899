import React from 'react';

import Card from '../../../../Components/Card/Card.js';
import CardCount from '../../../../Components/CardCount/CardCount.js';

import './Shuffling.scss';

const Shuffling = ( props ) =>
	<div className="shuffling">
		<div className="deck">
             <Card card={null} clickable={false} />
             <CardCount count={props.deck.length} />
        </div>
		{ props.isDealer === true ? <div className="shuffleButtons">
			<button onClick={() => props.onShuffle("wash")}>Wash</button>
			<button onClick={() => props.onShuffle("cut")}>Cut</button>
			<button onClick={() => props.onShuffle("riffle")}>Riffle</button>
			<button onClick={() => props.onShuffle("overhand")}>Overhand</button>
			<button onClick={() => props.onShuffle("deal")}>Deal</button>
		</div> : null }
	</div>

export default Shuffling;
