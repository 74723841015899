import React from 'react';

import './Card.scss';

class Card extends React.Component {

    onClick ( index )
    {
        if ( this.props.onClick )
        {
            this.props.onClick ( index, this.props.card );
        }
    }

    render() {

        var ratio = 1.6;

        if ( this.props.tiny === true )
        {
            ratio = 0.62;
        }

        return <div
                    className={"card " + ( this.props.card && this.props.card !== null ? ( this.props.tiny===true ? "tiny-" : "" ) + this.props.card.ordinal + "-" + this.props.card.suit : "turned" ) +
                                ( this.props.clickable === true ? " cardClickable" : "" ) }
                    style={{width: this.props.width ? this.props.width : 50, height: this.props.width ? this.props.width * ratio : 50 * ratio}}
                    onClick={() => this.onClick (this.props.index)}>
                </div>;
    }
}

export default Card;
