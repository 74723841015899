import React from "react";
import { withRouter } from 'react-router-dom';

import axios from "axios";

import Dialog from '../../../../Components/Dialog/Dialog.js';

class EnterName extends React.Component {

    constructor(props) {
        super(props);
        this.state = { name: "", error: null };
    }

    sitDown () {
        if ( this.state.name.trim ().length === 0 )
        {
            this.setState ( { error: "Please enter your name" } );
        }
        else
        {
            axios.post("/game/" + this.props.gameId + "/player/" + this.props.playerNumber, { name: this.state.name.trim () })
                .then(response => this.setState ( { playerId: response.data.id } ) )//this.props.onComplete(response))
                .catch(error => { console.log ( error ); this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.errorMessage : "Unknown Error"}); });
        }
    }

    componentDidUpdate ( prevProps, prevState, snapshot )
    {
        if ( this.state.playerId && !prevState.playerId )
        {
            this.props.onComplete ();
            this.props.history.push ( "/game/" + this.props.gameId + "/" + this.props.playerNumber + "/" + this.state.playerId );
        }
    }

    cancelSitDown () {
        this.props.onCancel ();
    }

    handleChange = (e) => {
        this.setState({name: e.target.value});
    }

    render() {
        var error = null;

        if ( this.state && this.state.error )
        {
            error = <p className="error-text">{this.state.error}</p>
        }

        return <Dialog
                    onOK={() => this.sitDown()}
                    onCancel={() => this.cancelSitDown()}>
                    <h1>Join the Game</h1>
                    {error}
                    <input type="text" placeholder="Enter your name" value={this.state.name} onChange={this.handleChange} autoFocus />
                </Dialog>;
    }
}

export default withRouter ( EnterName );
