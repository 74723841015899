import React from "react";

import Avatar from '../../../../Components/Avatar/Avatar.js';

import "./Seat.scss";

class Seat extends React.Component {

    toggleAvatar () {
        if ( this.props && this.props.toggleAvatar )
        {
            this.props.toggleAvatar ();
        }
    }

    render() {
      var position = null;
      var currentPlayer = null;

      switch ( this.props.seatNumber )
      {
          case 1:
          {
              position = "north";
              currentPlayer = this.props.game && this.props.game.player1 && this.props.game.player1.name ? this.props.game.player1.name : null;
              break;
          }
          case 2:
          {
              position = "east";
              currentPlayer = this.props.game && this.props.game.player2 && this.props.game.player2.name ? this.props.game.player2.name : null;
              break;
          }
          case 3:
          {
              position = "south";
              currentPlayer = this.props.game && this.props.game.player3 && this.props.game.player3.name ? this.props.game.player3.name : null;
              break;
          }
          case 4:
          {
              position = "west";
              currentPlayer = this.props.game && this.props.game.player4 && this.props.game.player4.name ? this.props.game.player4.name : null;
              break;
          }
          default:
          {
              position = null;
          }
      }

      if ( currentPlayer )
      {
          var standButton = null;

          if ( this.props.allowStand === true )
          {
              standButton = <p><button className="smallOrangeButton" onClick={() => this.props.handleStand(this.props.seatNumber)}>Stand</button></p>;
          }

          return <div className={"sit " + position}>
                        <Avatar avatar={this.props.avatar} width={40} onClick={() => this.toggleAvatar ()} clickable={this.props.toggleAvatar !== null}/>
                        <p>{currentPlayer}</p>
                        {standButton}
                </div>
      }
      else if ( this.props.allowSitIfEmpty === true )
      {
          return <button className={"sit " + position} onClick={() => this.props.handleSit(this.props.seatNumber)}>Sit</button>
      }
      else
      {
          return <div className={"sit occupied " + position}><p>Vacant</p></div>
      }
  }
}

export default Seat;
