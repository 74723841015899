import React from 'react';

import Avatar from '../../../../../Components/Avatar/Avatar.js';
import CardCount from '../../../../../Components/CardCount/CardCount.js';

import './Player.scss';

const Player = ( props ) =>
    <div className="player">
        <Avatar avatar={props.player.avatar} width={30} clickable={false}/>
        <p className={props.isCurrentPlayer === true ? "isCurrentPlayer" : null}>{props.player.name}</p>
        <CardCount count={props.cardCount} />
    </div>


export default Player;
