import React from "react";

import Card from   '../../../../../Components/Card/Card.js';
import Target from '../../../../../Components/Target/Target.js'

import './Stack.scss';

class Stack extends React.Component {

    render () {

        var cardComponents = null;
        var moreCardComponents = null;

        if ( this.props.cards && this.props.cards.length > 0 )
        {
            cardComponents = this.props.cards.map (
                ( card, index ) => <div key={index} className="stackCard"><Card card={card} tiny={true} width={40}/></div> );
        }

        if ( this.props.newCards && this.props.newCards.length > 0 )
        {
            moreCardComponents = this.props.newCards.map (
                ( card, index ) => <div key={"new" + index} className="stackCard"><Card card={card} tiny={true} width={40}/></div> );
        }

        return <div className="stack">
                    <Target isActive={this.props.selectingStack && ( ( cardComponents && cardComponents.length > 0 ) || ( moreCardComponents && moreCardComponents.length > 0 ) ) }>
                        <div className="stackCards" onClick={() => this.props.onSelectStack()}>
                            {cardComponents}
                            {moreCardComponents}
                        </div>
                    </Target>
                </div>;
    }
}

export default Stack;
