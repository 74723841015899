import React from "react";

import "./Message.scss";

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.keyFunction = this.keyFunction.bind(this);
  }

  keyFunction(event) {
     if (event.keyCode === 13) {
      this.props.onOK();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyFunction, false);
  }

  render() {
    const { children, onOK } = this.props;
    return (
      <div id="dialog-wrapper">
        <div id="blind" />
        <div id="dialog" style={{zIndex:1000}}>
          {children}
          <div className="buttons">
            <button className="button-red" onClick={onOK}>
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
