
import React from "react";

import "./Target.scss";

const Target = ( props ) =>
    <div className={"target " + ( props.isActive === true ? "targetActive" : "" )}>
        {props.children}
    </div>

export default Target;
