import React from "react";
import axios from "axios";

import Header from './Header/Header.js';
import Players from './Players/Players.js';
import Hand from './Hand/Hand.js';
import DownCards from './DownCards/DownCards.js';
import DeckAndDiscardPile from './DeckAndDiscardPile/DeckAndDiscardPile.js';
import Shuffling from './Shuffling/Shuffling.js';
import Message from '../../../Components/Message/Message.js';
import Dialog from '../../../Components/Dialog/Dialog.js';
import Card from '../../../Components/Card/Card.js';

import './Playing.scss';

class Playing extends React.Component {

    constructor ( props )
    {
        super ( props );
        this.state = {
                    error: null,
                    message: null,
                    cardsToPlace: null,
                    selectingStack: false,
                    wildCard: null,
                    confirmGoDown: false };
    }

    drawCard ()
    {
        if ( this.canDraw () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "draw" })
                .then(response => this.setState ( { message: response.data.drewCard, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error", cardsToPlace: null, selectingStack: false }));
        }
    }

    discardCard ( card )
    {
        if ( this.canDiscard () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "discard", card: card })
                .then(response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error", cardsToPlace: null, selectingStack: false }));
        }
    }

    pickup ()
    {
        if ( this.canPickup () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "pickup" })
                .then(response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorMessage : "Unknown Error " + JSON.stringify ( error ), cardsToPlace: null, selectingStack: false }));
        }
    }

    goDown ( cards )
    {
        axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "go-down", cards: this.state.cardsToPlace })
            .then (
                response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false, confirmGoDown: false, goDownCards: null  } )
            )
            .catch (
                error => this.setState ( { error: error.response ? error.response.data.errorMessage : "Unknown Error " + JSON.stringify ( error ),
                                            cardsToPlace: null,
                                            selectingStack: false,
                                            confirmGoDown: false,
                                            goDownCards: null } )
            );
    }

    canDiscard ()
    {
		return this.props.playerNumber === this.props.game.currentPlayer &&
             this.props.game.state === "TURN-POST-DRAW";
    }

    canDraw ()
    {
        return this.props.playerNumber === this.props.game.currentPlayer &&
             this.props.game.state === "TURN-PRE-DRAW";
    }

    canGoDown ()
    {
		return this.props.playerNumber === this.props.game.currentPlayer &&
             ( this.props.game.state === "TURN-PRE-DRAW" || this.props.game.state === "TURN-POST-DRAW" );
    }

    canPickup ()
    {
        return this.props.playerNumber === this.props.game.currentPlayer &&
             this.props.game.state === "TURN-PRE-DRAW";
    }

    canDeal ()
    {
        return this.props.playerNumber === this.props.game.currentPlayer &&
             this.props.game.state === "SHUFFLE";
    }

    canStartNextRound ()
    {
        return this.props.playerNumber === this.props.game.currentPlayer &&
             this.props.game.state === "ROUND-OVER";
    }

    createEmptyTable ()
    {
        return [
                { ordinal: "three", cards: [] },
                { ordinal: "four", cards: [] },
                { ordinal: "five", cards: [] },
                { ordinal: "six", cards: [] },
                { ordinal: "seven", cards: [] },
                { ordinal: "eight", cards: [] },
                { ordinal: "nine", cards: [] },
                { ordinal: "ten", cards: [] },
                { ordinal: "jack", cards: [] },
                { ordinal: "queen", cards: [] },
                { ordinal: "king", cards: [] },
                { ordinal: "ace", cards: [] },
                { ordinal: "wild", cards: [] }
            ];
    }

    findTablePileWithCard ( table, card )
    {
        for ( var i = 0; i < table.length; i ++ )
        {
            for ( var j = 0; j < table[i].cards.length; j ++ )
            {
                var check = table[i].cards[j];

                if ( check.id === card.id )
                {
                    return table[i];
                }
            }
        }

        return this.findTablePile ( table, card.ordinal );
    }

    findTablePile ( table, ordinal )
    {
        for ( var i = 0; i < table.length; i ++ )
        {
            if ( table[i].ordinal === ordinal ||
                ( table[i].ordinal === "wild" && ( ordinal === "two" || ordinal === "joker" ) ) )
            {
                return table[i];
            }
        }

        return null;
    }

    toggleCardOnTable ( card, isSelected )
    {
        var newCardsToPlace = null;

        if ( this.state.cardsToPlace !== null )
        {
            newCardsToPlace = JSON.parse ( JSON.stringify ( this.state.cardsToPlace ) );
        }
        else
        {
            newCardsToPlace = this.createEmptyTable ();
        }

        var pile = this.findTablePileWithCard ( newCardsToPlace, card );

        if ( isSelected )
        {
            pile.cards.push ( card );
        }
        else
        {
            var index = pile.cards.findIndex ( c => c.id === card.id );
            pile.cards.splice ( index, 1 );
        }

        this.setState ( { cardsToPlace: newCardsToPlace, selectingStack: isSelected && ( card.ordinal === "two" || card.ordinal === "joker" ), wildCard: card } );
    }

    onStackSelected ( ordinal )
    {
        if ( this.state.selectingStack === true )
        {
            var newCardsToPlace = null;

            if ( this.state.cardsToPlace !== null )
            {
                newCardsToPlace = JSON.parse ( JSON.stringify ( this.state.cardsToPlace ) );
            }
            else
            {
                newCardsToPlace = this.createEmptyTable ();
            }

            var pile = this.findTablePile ( newCardsToPlace, ordinal );
            var wildCardToPlace = { ...this.state.wildCard };
            pile.cards.push ( wildCardToPlace );

            var wildPile = this.findTablePile ( newCardsToPlace, "wild" );
            var index = wildPile.cards.findIndex ( c => c.ordinal === this.state.wildCard.ordinal && c.suit === this.state.wildCard.suit );
            wildPile.cards.splice ( index, 1 );

            this.setState ( { cardsToPlace: newCardsToPlace, selectingStack: false, wildCard: null } );
        }
    }

	startNextRound ()
	{
		if ( this.canStartNextRound () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "next-dealer" })
                .then(response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error", cardsToPlace: null, selectingStack: false }));
        }
	}

    deal ()
    {
        if ( this.canDeal () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: "deal" })
                .then(response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error", cardsToPlace: null, selectingStack: false }));
        }
    }

	shuffle ( shuffle )
	{
		if ( this.canDeal () )
        {
            axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { event: shuffle })
                .then(response => this.setState ( { message: null, error: null, cardsToPlace: null, selectingStack: false  } ) )
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error", cardsToPlace: null, selectingStack: false }));
        }
	}

    render ()
    {
        if ( !this.props || !this.props.game )
        {
            return <div className="full"></div>;
        }
        else
        {
            var game = this.props.game;
            var playerNumber = this.props.playerNumber;

            var cards = [];

            if ( playerNumber === 1 && game.cards )
            {
                cards = game.cards.hand1;
            }
            else if ( playerNumber === 2 && game.cards )
            {
                cards = game.cards.hand2;
            }
            else if ( playerNumber === 3 && game.cards )
            {
                cards = game.cards.hand3;
            }
            else if ( playerNumber === 4 && game.cards )
            {
                cards = game.cards.hand4;
            }

            var error = null;

            if ( this.state && this.state.error )
            {
                error = <p className="error-text">{this.state.error}</p>
            }

            var messageBox = null;

            if ( this.state.message != null )
            {
                messageBox =  <Message
                                onOK={() => this.setState({message: null})}>
                                <h1>Drew Card</h1>
                                <Card card={this.state.message} clickable={false} />
                            </Message>;
            }

            if ( this.state.confirmGoDown === true )
            {
                messageBox = <Dialog
                                onOK={() => this.goDown ( this.state.goDownCards ) }
                                onCancel={() => this.setState ( { confirmGoDown: false, goDownCards: null, cardsToPlace: null } )}>
                                <h1>Confirm Go Down</h1>
                                <DownCards stacks={(playerNumber === 1 || playerNumber === 3) ? game.cards.table13 : game.cards.table24}
                                            newStacks={this.state.cardsToPlace}
                                            selectingStack={this.state.selectingStack}
                                            onStackSelected={(ordinal) => this.onStackSelected(ordinal)} />
                            </Dialog>;

            }

			var tableContents;

			if ( this.props.game.state === "SHUFFLE" )
			{
				tableContents = <div className="tableCards"><Shuffling deck={game.cards.deck} onShuffle={(shuffle) => this.shuffle(shuffle)} isDealer={this.canDeal()}/></div>
			}
			else
			{
				tableContents = <div className="tableCards">
									<DownCards stacks={(playerNumber === 1 || playerNumber === 3) ? game.cards.table24 : game.cards.table13} />
									<DeckAndDiscardPile discard={game.cards.discard}
												deck={game.cards.deck}
												canDraw={this.canDraw ()}
												onDraw={() => this.drawCard ()}
												canPickup={this.canPickup ()}
												onPickup={() => this.pickup ()}/>
									<DownCards stacks={(playerNumber === 1 || playerNumber === 3) ? game.cards.table13 : game.cards.table24}
												newStacks={this.state.cardsToPlace}
												selectingStack={this.state.selectingStack}
												onStackSelected={(ordinal) => this.onStackSelected(ordinal)} />
									<Hand cards={cards}
												canStartNextRound={this.canStartNextRound ()}
												startNextRound={() => this.startNextRound ()}
												canDiscard={this.canDiscard ()}
												onDiscard={(card) => this.discardCard ( card ) }
												canGoDown={this.canGoDown ()}
												onGoDown={(cards) => this.setState ( { confirmGoDown: true, goDownCards: cards } ) }
												goDownPointsRequired={game.currentPlayer.goDownPointsRequired}
												onCardToggle={(card, isSelected) => this.toggleCardOnTable ( card, isSelected)}/>
								</div>;
			}

            return <div className="full">
                        <Header name={game.name} state={game.state} round={game.round} playerNumber={playerNumber} score13={game.score13} score24={game.score24} events={this.props.events} />
                        {error}
                        <div className="gameTable">
                            <div className="tablePlayers">
                                <Players game={game} />
                            </div>
							{tableContents}
                        </div>
                        {messageBox}
                   </div>
        }
    }
}

export default Playing;
