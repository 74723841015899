import React from "react";
import axios from "axios";

import Dialog from '../../../Components/Dialog/Dialog.js';

class CreateGame extends React.Component {

    constructor(props) {
        super(props);
        this.state = { name: "", error: null };
    }

    createGame () {
        if ( this.state.name.trim ().length === 0 )
        {
            this.setState ( { error: "Please enter a name" } );
        }
        else
        {
            axios.post('/game', { name: this.state.name.trim () })
                .then(response => this.props.onComplete(response.data))
                .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error"}));
        }
    }

    cancelCreateGame () {
        this.props.onCancel ();
    }

    handleChange = (e) => {
        this.setState({name: e.target.value});
    }

    render() {
        var error = null;

        if ( this.state && this.state.error )
        {
            error = <p className="error-text">{this.state.error}</p>
        }

        return <Dialog
                    onOK={() => this.createGame()}
                    onCancel={() => this.cancelCreateGame()}>
                    <h1>Create a Game</h1>
                    {error}
                    <input type="text" placeholder="Enter game name" value={this.state.name} onChange={this.handleChange} autoFocus />
                </Dialog>;
    }
}

export default CreateGame;
