import React from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";

import EnterName from './EnterName/EnterName.js';
import Seat from './Seat/Seat.js';

import "./Joining.scss";

class Join extends React.Component {

    constructor ( props )
    {
        super ( props );

        this.state = {
            error: null,
            joining: false,
            requestedPlayerNumber: null
        };
    }

    componentDidUpdate ( prevProps, prevState, snapshot )
    {
        // if ( ( prevState.playerId !== this.props.playerId ) ||
        //      ( prevState.playerNumber !== this.props.playerNumber ) )
        // {
        //     this.setState ( { playerId: this.props.playerId, playerNumber: this.props.playerNumber, isReady: false, avatar: 1 } );
        // }
    }

    handleSit ( playerNumber )
    {
        this.setState ( { joining: true, requestedPlayerNumber: playerNumber } );//, playerNumber: playerNumber, isReady: false } );
    }

    handleStand ( playerNumber )
    {
        axios.delete ( "/game/" + this.props.game.id + "/player/" + playerNumber + "/" + this.props.playerId )
            .then ( response => this.props.history.push ( "/game/" + this.props.game.id ) )
            .catch ( error => alert ( "failed to stand: " + JSON.stringify ( error ) ) );
    }

    cancelSit ()
    {
        this.setState ( { joining: false, requestedPlayerNumber: null } );
    }

    completeSit ()
    {
        this.setState ( { joining: false } );
    }

    toggleReadiness ()
    {
        axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { isReady: !this.getIsReady () })
            .then(response => this.setState ( { isReady: !this.getIsReady () } ))
            .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error"}));
    }

    toggleAvatar ()
    {
        var avatar = this.props.avatar ? this.props.avatar + 1 : 1;

        if ( avatar === 13 )
        {
            avatar = 1;
        }

        axios.post("/game/" + this.props.game.id + "/player/" + this.props.playerNumber + "/" + this.props.playerId, { avatar: avatar })
            .then(response => this.setState ( { avatar: avatar } ) )
            .catch(error => this.setState({error: error.response ? error.response.data.errorCode + " - " + error.response.data.message : "Unknown Error"}));
    }

    leaveGame ()
    {
        this.props.leaveHandler ( this.props.playerNumber, this.props.playerId );
    }

    getIsReady ()
    {
        var isReady = false;

        if ( this.props && this.props.game )
        {
            if ( this.props.playerNumber === 1 )
            {
                isReady = this.props.game.player1.isReady;
            }
            else if ( this.props.playerNumber === 2 )
            {
                isReady = this.props.game.player2.isReady;
            }
            else if ( this.props.playerNumber === 3 )
            {
                isReady = this.props.game.player3.isReady;
            }
            else if ( this.props.playerNumber === 4 )
            {
                isReady = this.props.game.player4.isReady;
            }
        }

        return isReady;
    }

    render () {

        if ( this.props.game )
        {
            var error = null;

            if ( this.state && this.state.error )
            {
                error = <p className="error-text">{this.state.error}</p>
            }

            var enterName = null;

            if ( this.state && this.state.joining === true )
            {
                enterName = <EnterName gameId={this.props.game.id}
                                playerNumber={this.state.requestedPlayerNumber}
                                onCancel={() => this.cancelSit()}
                                onComplete={() => this.completeSit()} />
            }

            var isReady = this.getIsReady ();

            var readiness = null;

            if ( this.props.playerNumber !== null && isReady !== true )
            {
                readiness = <button onClick={() => this.toggleReadiness()}>I'm Ready</button>;
            }
            else if ( this.props.playerNumber !== null && isReady === true )
            {
                readiness = <a onClick={() => this.toggleReadiness()} href="#">Wait, I'm not ready</a>;
            }


            return <div>
                    <h1>{this.props.game.name}</h1>
                    {error}

                    <div className="table">
                        <Seat seatNumber={1}
                                allowSitIfEmpty={this.props.playerNumber === null}
                                allowStand={this.props.playerNumber === 1 && isReady !== true}
                                avatar={this.props.game && this.props.game.player1 && this.props.game.player1.avatar ? this.props.game.player1.avatar : null}
                                toggleAvatar={this.props.playerNumber === 1 && !isReady ? () => this.toggleAvatar () : null}
                                game={this.props.game}
                                handleSit={(seatNumber) => this.handleSit(seatNumber)}
                                handleStand={(seatNumber) => this.handleStand(seatNumber)}>Sit</Seat>
                        <Seat seatNumber={2}
                                allowSitIfEmpty={this.props.playerNumber === null}
                                allowStand={this.props.playerNumber === 2 && isReady !== true}
                                avatar={this.props.game && this.props.game.player2 && this.props.game.player2.avatar ? this.props.game.player2.avatar : null}
                                toggleAvatar={this.props.playerNumber === 2 && !isReady ? () => this.toggleAvatar () : null}
                                game={this.props.game}
                                handleSit={(seatNumber) => this.handleSit(seatNumber)}
                                handleStand={(seatNumber) => this.handleStand(seatNumber)}>Sit</Seat>
                        <Seat seatNumber={3}
                                allowSitIfEmpty={this.props.playerNumber === null}
                                allowStand={this.props.playerNumber === 3 && isReady !== true}
                                avatar={this.props.game && this.props.game.player3 && this.props.game.player3.avatar ? this.props.game.player3.avatar : null}
                                toggleAvatar={this.props.playerNumber === 3 && !isReady ? () => this.toggleAvatar () : null}
                                game={this.props.game}
                                handleSit={(seatNumber) => this.handleSit(seatNumber)}
                                handleStand={(seatNumber) => this.handleStand(seatNumber)}>Sit</Seat>
                        <Seat seatNumber={4}
                                allowSitIfEmpty={this.props.playerNumber === null}
                                allowStand={this.props.playerNumber === 4 && isReady !== true}
                                avatar={this.props.game && this.props.game.player4&& this.props.game.player4.avatar ? this.props.game.player4.avatar : null}
                                toggleAvatar={this.props.playerNumber === 4 && !isReady ? () => this.toggleAvatar () : null}
                                game={this.props.game}
                                handleSit={(seatNumber) => this.handleSit(seatNumber)}
                                handleStand={(seatNumber) => this.handleStand(seatNumber)}>Sit</Seat>
                    </div>
                    {enterName}
                    <div className="buttonsDiv">
                        { isReady === false ? <button onClick={() => this.leaveGame()}>Leave Game</button> : null }
                        {readiness}
                    </div>
                </div>;
        }
        else
        {
            return <div><h1>Loading... </h1></div>;
        }
    }
}

export default withRouter ( Join );
