import React from 'react';

import './Avatar.scss';

const Avatar = ( props ) =>
    <div onClick={() => props.onClick ? props.onClick() : null}
        className={"avatar avatar" + ( props.avatar === null ? "1" : props.avatar ) + ( props.clickable === true ? " avatarClickable" : "") }
        style={{width: props.width ? props.width : 100, height: props.width ? props.width * 1.435 : 100 * 1.435}}>
    </div>


export default Avatar;
