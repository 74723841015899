import React from "react";
import axios from "axios";
import { withRouter } from 'react-router-dom'

import CreateGame from './CreateGame/CreateGame.js';
import GameList from './GameList/GameList.js';

class GameSetup extends React.Component {

    constructor ( props )
    {
        super ( props );

        this.state = {
            games: null,
            error: null,
            creatingGame: false
        };
    }

    componentDidMount() {
        axios.get("/games")
            .then(json => this.setState({games: json.data}))
            .catch(error => this.setState({error: error}));
    }

    handleCreateGameButton() {
        this.setState ( { creatingGame: true } );
    }

    createGame(game) {
        this.props.history.push("/game/" + game.id);
    }

    cancelCreateGame() {
        this.setState ( { creatingGame: false } );
    }

    render() {
        var content = <h1>Loading...</h1>;

        if ( this.state && this.state.games )
        {
            content = <GameList games={this.state.games} />;
        }
        else if ( this.state && this.state.error )
        {
            content = <p>{JSON.stringify(this.state.error)}</p>;
        }

        var creatingGame = null;

        if ( this.state && this.state.creatingGame === true )
        {
            creatingGame = <CreateGame onComplete={(game) => this.createGame(game)} onCancel={() => this.cancelCreateGame()}/>;
        }

        return <div>
                    <h1>Quarantine Canasta</h1>
                    {content}
                    <button onClick={() => this.handleCreateGameButton()}>Create New Game</button>
                    {creatingGame}
                    <p>&nbsp;</p>
            </div>;
    }
}

export default withRouter(GameSetup);
