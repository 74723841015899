import React from 'react';

import Player from './Player/Player.js';

import './Players.scss';

const Players = ( props ) =>
    <div className="players">
        <Player key="player-1" player={props.game.player1} isCurrentPlayer={props.game.currentPlayer === 1} cardCount={props.game.state === "SHUFFLE" ? 0 : Array.isArray(props.game.cards.hand1) ? props.game.cards.hand1.length : props.game.cards.hand1} />
        <Player key="player-2" player={props.game.player2} isCurrentPlayer={props.game.currentPlayer === 2} cardCount={props.game.state === "SHUFFLE" ? 0 : Array.isArray(props.game.cards.hand2) ? props.game.cards.hand2.length : props.game.cards.hand2} />
        <Player key="player-3" player={props.game.player3} isCurrentPlayer={props.game.currentPlayer === 3} cardCount={props.game.state === "SHUFFLE" ? 0 : Array.isArray(props.game.cards.hand3) ? props.game.cards.hand3.length : props.game.cards.hand3} />
        <Player key="player-4" player={props.game.player4} isCurrentPlayer={props.game.currentPlayer === 4} cardCount={props.game.state === "SHUFFLE" ? 0 : Array.isArray(props.game.cards.hand4) ? props.game.cards.hand4.length : props.game.cards.hand4} />
    </div>


export default Players;
