import React from "react";

import Card from '../../../../Components/Card/Card.js';
import Target from '../../../../Components/Target/Target.js';

import './Hand.scss';

class Hand extends React.Component {

    constructor ( props )
    {
        super ( props );
        this.state = { selectedCards: [] };
    }

    discard ()
    {
        var index = this.state.selectedCards[0];
        var card = this.props.cards[index];
        this.props.onDiscard ( card );
        this.setState ( { selectedCards: [] } );
    }

    goDown ()
    {
        var cards = [];

        this.state.selectedCards.map ( index => cards.push ( this.props.cards[index] ) );

        this.props.onGoDown ( cards );
        this.setState ( { selectedCards: [] } );
    }

    isSelected ( index )
    {
        return this.state.selectedCards.includes ( index );
    }

    toggleSelection ( cardIndex )
    {
        if ( this.props.canDiscard || this.props.canGoDown )
        {
            var newSelection = [...this.state.selectedCards];
            if ( this.state.selectedCards.includes ( cardIndex ) )
            {
                var arrayIndex = newSelection.indexOf ( cardIndex );
                newSelection.splice ( arrayIndex, 1 );
                this.props.onCardToggle ( this.props.cards[cardIndex], false );
            }
            else
            {
                newSelection.push ( cardIndex );
                this.props.onCardToggle ( this.props.cards[cardIndex], true );
            }

            // If multiple selected, unselect black threes
            if ( newSelection.length > 1 )
            {
                for ( var i = 0; i < newSelection.length; i ++ )
                {
                    var cardToCheck = this.props.cards[newSelection[i]];

                    if ( cardToCheck.ordinal === "three" )
                    {
                        newSelection.splice ( i, 1 );
                        this.props.onCardToggle ( cardToCheck, false );
                        i = 0;
                    }
                }
            }

            this.setState ( { selectedCards: newSelection } );
        }
    }

    canDiscard ()
    {
        return this.state.selectedCards && this.state.selectedCards.length === 1;
    }

    canGoDown ()
    {
        var blackThreeSelected = false;

        if ( this.state.selectedCards && this.state.selectedCards.length > 0 )
        {
            for ( var i = 0; i < this.state.selectedCards.length; i ++ )
            {
                var card = this.props.cards[this.state.selectedCards[i]];

                if ( card.ordinal === "three" && ( card.suit === "spades" || card.suit === "clubs" ) )
                {
                    blackThreeSelected = true;
                }
            }
        }

        return this.state.selectedCards &&
                this.state.selectedCards.length >= 1 &&
                this.state.selectedCards.length < this.props.cards.length &&
                !blackThreeSelected;
    }

    render () {

        var cardComponents = null;
        var canSelectCards = this.props.canGoDown || this.props.canDiscard;

        if ( this.props && this.props.hidden !== true && this.props.cards !== null )
        {
            cardComponents = this.props.cards.map (
                ( card, index ) => <div className={"cardWrapper " + ( this.isSelected ( index ) ? " cardSelected" : "" ) }

                                        key={index}>
                                        <Card card={card} selected={true}
                                            index={index}
                                            clickable={canSelectCards} onClick={ ( index ) => this.toggleSelection ( index ) } />
                                    </div> );
        }
        else if ( this.props && this.props.hidden === true )
        {
            cardComponents = [];

            for ( var i = 0; i < this.props.cardCount; i ++ )
            {
                cardComponents.push ( <div className="cardWrapper" key={i}><Card ordinal={null} suit={null} /></div> );
            }
        }

        var deal = null;

        if ( this.props.canStartNextRound === true )
        {
            deal = <button onClick={() => this.props.startNextRound ()}>Start Next Round</button>;
        }

        return <div className="hand">
                    <Target isActive = {canSelectCards}>
                        <div className="cardContainer">
                            {cardComponents}
                        </div>
                    </Target>
                    <div className="handButtons">
                        <button disabled={this.canDiscard () === false || !this.props.canDiscard} onClick={() => this.discard()}>Discard</button>
                        <button disabled={this.canGoDown () === false} onClick={() => this.goDown()}>Go Down</button>
                        {deal}
                    </div>
                </div>;
    }
}

export default Hand;
