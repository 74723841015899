import React from 'react';
import Moment from 'react-moment';
import { Link } from "react-router-dom";

class GameList extends React.Component {
    render() {

            if ( !this.props.games )
            {
                return <h2>Waiting for games...</h2>;
            }
            else if ( this.props.games.length === 0 )
            {
                return <h2>There are no games to join</h2>;
            }
            else
            {
                var games = this.props.games.map(g => <tr key={g.id}>
                                    <td>{g.name}</td>
                                    <td>Created <Moment fromNow>{g.createdAt}</Moment></td>
                                    <td><Link to={'/game/' + g.id}>Join</Link></td>
                                </tr>);

                return <div>
                        <h2>Games</h2>
                        <table>
                            <tbody>
                                {games}
                            </tbody>
                        </table>
                    </div>
            }
    }
}

export default GameList;
