import React from 'react';

import Card from '../../../../Components/Card/Card.js';
import Target from '../../../../Components/Target/Target.js';
import CardCount from '../../../../Components/CardCount/CardCount.js';

import './DeckAndDiscardPile.scss';

const DeckAndDiscardPile = ( props ) =>
	<div className="stackDiscard">
		<div className="discard">
            <Target isActive={props.canPickup === true}>
                 <Card card={props.discard.topCard !== null ? props.discard.topCard : null} clickable={props.canPickup === true} onClick={ () => props.onPickup ()} />
                 <CardCount count={props.discard.count} />
             </Target>
        </div>
		<div className="draw">
            <Target isActive={props.canDraw === true}>
                <Card card={null} clickable={props.canDraw === true} onClick={ () => props.onDraw ()} />
                <CardCount count={props.deck} />
            </Target>
        </div>
	</div>

export default DeckAndDiscardPile;
